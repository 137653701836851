import { module } from "modujs";

export default class SoundButton extends module {

  static CLASS_ACTIVE = "is-active";
  constructor(m) {
    super(m);

    this.Click = this.toggle.bind(this);

    this.$el = this.el;
  }
  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.$el.addEventListener('click', this.Click);
  }

  unbindEvents() {
    this.$el.removeEventListener('click', this.Click);
  }

  toggle() {

    const videoActive = document.querySelector("[data-module-video].-active").querySelector("video");

    if (!videoActive) return

    if (!this.$el.classList.contains(SoundButton.CLASS_ACTIVE)) {
      this.active();
      this.call('unmuted', { target: videoActive }, 'Video');
    } else {
      this.unactive();
      this.call('muted', { target: videoActive }, 'Video');
    }
  }

  active() {
    this.$el.classList.add(SoundButton.CLASS_ACTIVE);
  }

  unactive() {
    this.$el.classList.remove(SoundButton.CLASS_ACTIVE);
  }

  destroy() {
    super.destroy();
    this.unbindEvents();
  }
}