import { $body } from "./dom";




const createMap = () => {
  const $map = document.querySelector('[data-map-script]');
  const $script = document.createElement('script');

  // if (!$map) return

  $script.src = $map.getAttribute('data-map-script');
  $script.setAttribute('defer', '');
  $script.setAttribute('id', 'mapbox-gl');

  $body.appendChild($script);
}

export default createMap