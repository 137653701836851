import { module } from "modujs";

export default class Store extends module {

  constructor(m) {
    super(m)

    this.$el = this.el;

    this.$list = this.$('list')[0];

    // Empty
    this.$empty = this.getData('empty') || 0;
    this.$emptyName = this.getData('empty-name') || "empty name";
    this.$emptyTime = this.getData('empty-time') || "empty time";



  }

  init() {

    if (this.$empty) {

      for (let i = 0; i < this.$empty; i++) {

        this.$list.insertAdjacentHTML("beforeend", this.createEmpty());
      }
    }
  }

  createEmpty() {


    const emptyBlock = `<button disabled aria-disabled="true" aria-type="button" aria-label="${this.$emptyName}" role="listitem" class="c-store-listing_item">
  <div class="c-store-listing_header">
    <span class="c-store-listing_title">${this.$emptyName}</span>
  </div>
</button>`;

    return emptyBlock

  }

  destroy() {
    super.destroy();
  }
}

