import { module } from "modujs";
import { $html } from "../utils/dom";

export default class EasterEgg extends module {
  static CLASS = "has-easter-egg-open";

  constructor(m) {
    super(m);

    this.Click = this.toggle.bind(this);

    this.$el = this.el;

  }
  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.$el.addEventListener('click', this.Click);
  }

  unbindEvents() {
    this.$el.removeEventListener('click', this.Click);
  }
  toggle() {
    if ($html.classList.contains(EasterEgg.CLASS)) {
      this.close();
    }
    else {
      this.open();
    }
  }

  open() {
    $html.classList.add(EasterEgg.CLASS);
    this.$el.textContent = '[ х ]';
  }

  close() {
    $html.classList.remove(EasterEgg.CLASS);
    this.$el.textContent = '[ ? ]';
  }

  destroy() {
    super.destroy();
    this.unbindEvents();
  }
}