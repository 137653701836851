import { module } from "modujs";
import IS_TOUCH_DEVICE from "../app";

export default class extends module {

  constructor(m) {
    super(m);

    // Elements

    this.$el = this.el
    this.$radio = this.$("radio");
    this.$items = this.$("item");
    this.$list = this.$("list")[0];
    this.$link = this.$("link");

    // Events

    this.events = {
      change: {
        "radio": "Changed"
      }
    }
  }

  init() {
    console.log()
    this.on()

    this.$link.forEach((link) => {
      const slug = link.getAttribute("data-slug")
      const location = window.location.pathname

      if (location.split("/").pop() === slug || location.split("/")[2] === slug) {
        link.classList.add("w--current")
        link.setAttribute("aria-current", "page")
      }
      else {
        link.classList.remove("w--current")
        link.removeAttribute("aria-current")
      }
    })

  }



  on() {
    this.$radio.forEach((el, i) => {
      if (window.location.pathname.split("/").pop() === el.value) {
        el.checked = true
        el.parentNode.parentNode.classList.add("is-active")

        if (IS_TOUCH_DEVICE || window.innerWidth < 1000) {
          const xCoord = (el.parentNode.parentNode.offsetLeft - document.documentElement.clientWidth / 4)
          this.$list.scrollTo(xCoord, 0)
        }
      }
      if (window.location.pathname.split("/").length === 3 && i === 0) {
        el.checked = true
        el.parentNode.parentNode.classList.add("is-active")
      }
    })
  }

  off() {
    this.$radio.forEach((el) => {
      el.parentNode.parentNode.classList.remove("is-active")
    })
  }

  Changed(e) {

    e.preventDefault()


    let url = e.target.value


    if (url !== "all") {
      url = `${window.location.pathname.split("/", 3).join("/")}/${e.target.value}`
    }

    if (url === "all") {
      url = window.location.pathname.split("/", 3).join("/")
    }



    this.off()

    if (!url) return

    e.target.parentNode.parentNode.classList.add("is-active")

    if (IS_TOUCH_DEVICE || window.innerWidth < 1000) {
      const xCoord = e.target.parentNode.parentNode.offsetLeft - window.innerWidth / 4
      this.$list.scrollTo({
        left: xCoord,
        top: 0,
        behavior: 'smooth'
      })
    }

    this.call('goTo', { url: url, transition: 'listing' }, 'Load')


  }
}