import { module } from "modujs";

export default class MenuButton extends module {
  constructor(m) {
    super(m);

    this.Click = this.open.bind(this);

    this.$el = this.el;
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.$el.addEventListener('click', this.Click);
  }

  unbindEvents() {
    this.$el.removeEventListener('click', this.Click);
  }

  open() {
    this.call('open', null, 'Menu');
  }

  destroy() {
    super.destroy();
    this.unbindEvents();
  }
}