import { module } from "modujs";
import { $html } from "../utils/dom";

export default class Menu extends module {

  static MENU_OPEN_CLASS = "has-menu-open";
  constructor(m) {
    super(m);


    this.$el = this.el;
    this.$items = this.$("item");

    // Events

    this.events = {
      click: {
        "close": "close"
      }
    }

  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.$el.addEventListener('click', this.Click);
  }

  unbindEvents() {
    this.$el.removeEventListener('click', this.Click);
  }

  toggle() {
    if ($html.contains(Menu.MENU_OPEN_CLASS)) {
      this.open();
    }
    else {
      this.close();
    }
  }

  open() {
    $html.classList.add(Menu.MENU_OPEN_CLASS);
    // this.call('stop', null, 'Scroll');
  }

  close() {
    $html.classList.remove(Menu.MENU_OPEN_CLASS);
    // this.call('start', null, 'Scroll');
  }

  destroy() {
    super.destroy();
    this.unbindEvents();
  }
}