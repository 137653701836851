import { module } from "modujs";

export default class extends module {

  constructor(m) {
    super(m);

    this.$items = this.$("item");
    this.$content = this.$("content");

    // this.events = {
    //   click: {
    //     "item": "clickItem"
    //   }
    // }
  }

  init() {

    // this.$items.forEach((el) => {
    //   // el.classList.remove("is-active");
    //   el.addEventListener("click", (e) => {

    //     e.preventDefault();
    //     el.classList.add("is-active");

    //     this.$content.forEach((item) => {
    //       item.classList.remove("is-active");
    //     });

    //   });
    // });

    const url = window.location.href;
    const id = url.split("#").pop();
    // console.log(url, id);

    this.$content.forEach((el) => {

      if (el.getAttribute("id") === id) {
        setTimeout(() => {
          this.call("scrollTo", { target: el, options: { offset: -30, duration: 0, immediate: false } }, "Scroll");
        }, 1)
      }
    });


  }

  clickItem(e) {

    const target = e.curTarget;

    this.$content.forEach((item) => {
      item.classList.remove("is-active");

      if (item.getAttribute("data-id") === target.getAttribute("data-id")) {
        item.classList.add("is-active");
      }
    });

    this.$items.forEach((item) => {
      item.classList.remove("is-active");
    });

    target.classList.add("is-active");


  }
  destroy() {
  }
}