import { module } from "modujs";
import { $html } from "../utils/dom";

export default class Modal extends module {

  static CLASS = "has-modal-open";
  constructor(m) {
    super(m)

    this.onCloseBind = this.onCloseEvent.bind(this)

    this.events = {
      click: {
        "close": "close"
      }
    }
  }

  init() {
    // $html.classList.add(Modal.CLASS);



  }

  bindEvents() {
    window.addEventListener('keyup', this.onCloseBind)
  }

  unbindEvents() {
    window.removeEventListener('keyup', this.onCloseBind)
  }

  onCloseEvent(e) {
    if (e.key === 'Escape') {
      e.preventDefault();
      this.close();
    }
  }




  open() {
    this.bindEvents();
    $html.classList.add(Modal.CLASS);
    this.call('stop', null, 'Scroll');
  }

  close() {
    this.unbindEvents();
    $html.classList.remove(Modal.CLASS);
    this.call('start', null, 'Scroll');
  }

  destroy() {
    super.destroy();
    this.unbindEvents();
  }
}