import { module } from 'modujs';
// import modularLoad from 'modularload';
import modularLoad from '../utils/modularload';
// import { gsap } from 'gsap';
import { $html, $body } from '../utils/dom';
// import createMap from '../utils/mapbox-gl';

export default class Load extends module {

  static ENTER_DELAY = 1200;
  static SIZE_DELAY = 600
  static TRANSITION_DELAY = 400
  static DELAY = 600



  constructor(m) {
    super(m);


    // this.$map = document.querySelector('#mapbox-gl')
  }





  init() {
    // console.log($map)
    // if (document.querySelector('[data-map-script]') && !document.querySelector('#mapbox-gl')) {
    //   createMap();
    //   this.call('initMap', null, 'Map');
    // }

    // gsap.delayedCall(0.6, () => {
    //   this.setSizes();
    // });
    // setTimeout(() => {
    this.setSizes();
    // }, Load.DELAY);

    this.load = new modularLoad({
      enterDelay: Load.ENTER_DELAY,
      transitions: {
        listing: {
          enterDelay: 500
        }
      }
    });

    this.load.on('loading', (transition, oldContainer) => {

      if (transition == 'listing') {
        this.call('scrollTo', { target: oldContainer.parentNode.querySelector('[data-load-container="listing"]'), options: { offset: -80, duration: 0.8 } }, 'Scroll')

        this.call('removeScrollElements', oldContainer, 'Scroll')
      }
      else {
        this.call('destroy', oldContainer, 'Map');

        $html.classList.add('has-transition');
        $html.classList.remove('has-dom-ready');
        $html.classList.remove('has-dom-ready-callback');
        $html.classList.remove('has-menu-open');
        $html.classList.remove('has-modal-open');
        $html.classList.remove('is-bottom');

        this.call("close", null, "EasterEgg");
      }






    });

    this.load.on('loaded', (transition, oldContainer, newContainer) => {
      this.call('destroy', oldContainer, 'app');
      this.call('destroy', oldContainer, 'Map');
      this.call('update', newContainer, 'app');


      // $html.classList.remove('has-menu-open');

      // console.log(newContainer.querySelector('[data-map-script]'), newContainer.querySelector('#mapbox-gl'))
      // if (newContainer.querySelector('[data-map-script]')) {
      //   this.call('initMap', newContainer, 'Map');
      //   if (document.querySelector('#mapbox-gl')) return
      //   createMap();

      // }

      // gsap.delayedCall(0.6, () => {
      //   this.setSizes();
      // });
      setTimeout(() => {
        this.setSizes();
      }, Load.DELAY);

      // gsap.delayedCall(0.4, () => {
      //   $html.classList.remove('has-transition');
      // })

      setTimeout(() => {
        $html.classList.remove('has-transition');
      }, Load.TRANSITION_DELAY);
    });

    this.load.on('ready', (transition, newContainer) => {
      // this.call('computeCurrentItem', null, 'Navigation')

      if (transition == 'listing') {

        this.call('addScrollElements', newContainer, 'Scroll')
      }
    })
  }



  setSizes() {

    if (window.innerHeight - document.documentElement.clientHeight > 0) {
      $body.classList.add('has-scrollbar-y');
      document.documentElement.style.setProperty('--scrollbar', `${window.innerHeight - document.documentElement.clientHeight}px`);
    } else {
      $body.classList.remove('has-scrollbar-y');
    }

    // screen height
    document.documentElement.style.setProperty('--app-availheight', `${window.screen.availHeight}px`);
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    document.documentElement.style.setProperty('--app-height-negative', `-${window.innerHeight}px`);
    document.documentElement.style.setProperty('--app-scrollbar-width', `${this.scrollbarWidth()}px`);




    this.scrollbarWidth()

    window.addEventListener('resize', () => {

      if (!window.isMobile) {
        document.documentElement.style.setProperty('--app-availheight', `${window.screen.availHeight}px`);
        document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
        document.documentElement.style.setProperty('--app-height-negative', `-${window.innerHeight}px`);
      }
    });
  }

  scrollbarWidth() {
    // Create the div
    const scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    scrollDiv.setAttribute("style", "width: 100px; height: 100px; overflow: scroll; position: absolute; top: -9999px;");
    document.body.appendChild(scrollDiv);

    // Get the scrollbar width
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    // console.log(scrollbarWidth)
    // Delete the div
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
  }

  goTo(obj) {
    this.load.goTo(obj.url, obj.transition);
  }
}
