import { module } from 'modujs';

export default class extends module {
  constructor(m) {
    super(m);

    this.onResizeBind = this.onResize.bind(this);

    this.$el = this.el;
    this.$target = this.$('target')[0];
    this.$button = this.$('button')[0];


    this.options = {
      selector: this.$target,
      breakpoints: { default: { src: this.$target.getAttribute('data-src') } }
    };


    this.events = {
      click: {
        "button": "onChangeSound"
      }
    }

  }
  init() {
    this.bindEvents();
    // get a list of video switching points and links to the videos themselves 
    this.$target.querySelectorAll('[data-src]').forEach(element => this.options.breakpoints[element.getAttribute('data-mw')] = { src: element.getAttribute('data-src') });
    this.$target.innerHTML = ''; // we clean up so that there is nothing superfluous 

    // run the handler and track the change in screen width
    this.responseVideo(this.options);
  }

  onChangeSound(e) {
    const target = e.curTarget;

    if (!target.classList.contains('is-active')) {
      target.classList.add('is-active');
      this.$target.muted = false;
    } else {
      target.classList.remove('is-active');
      this.$target.muted = true;
    }

  }

  muted(options) {
    const { target } = options
    target.muted = true;
  }

  unmuted(options) {
    const { target } = options
    target.muted = false;
  }


  onResize() {
    this.responseVideo(this.options)
  }

  /** Function runs on resize  */
  bindEvents() {
    window.addEventListener("resize", this.onResizeBind);
  }

  unbindEvents() {
    window.removeEventListener("resize", this.onResizeBind);
  }

  /** 
   * Change src value of video link to fit screen width 
   * 
   * @param {Object} options object with options 
   */
  responseVideo(options) {
    const { selector, breakpoints } = options; // get options


    const widthNow = selector.getAttribute('data-width-now') || null;

    const maxBreakpoint = Math.max.apply(Math, Object.keys(breakpoints).filter(key => key <= document.body.clientWidth).map(Number));
    const nowBreakpoint = maxBreakpoint || 'default'; // choose either the maximum value, if not, then the default 

    if (widthNow && widthNow == nowBreakpoint) return; // check if the video needs to be changed 


    selector.src = breakpoints[nowBreakpoint]?.src || "";
    selector.setAttribute('data-width-now', nowBreakpoint);
  }

  destroy() {
    super.destroy();
    this.unbindEvents();
  }
}