import { module } from "modujs";
import Swiper from "swiper";
import { Navigation, Pagination, Parallax, Autoplay } from "swiper/modules";

export default class extends module {
  constructor(m) {
    super(m);

    // Elements

    this.$el = this.el;
    this.$slider = this.$('slider')[0];
    this.$prev = this.$('prev')[0];
    this.$next = this.$('next')[0];
    this.$pagination = this.$('pagination')[0];

    this.$autoplay = this.getData('autoplay') !== null ? true : false;
    this.$delay = this.getData('autoplay') !== null ? this.getData('autoplay') : 3000;

  }

  init() {



    Swiper.use([Navigation, Pagination, Parallax, Autoplay]);


    this.options = {
      slidesPerView: "auto",
      parallax: true,
      navigation: {
        nextEl: this.$next,
        prevEl: this.$prev
      },
      pagination: {
        el: this.$pagination,
        type: "fraction",
      },
      speed: 600,
      grabCursor: true,
      slideActiveClass: "-active",
    }


    if (this.$autoplay) {
      this.options.autoplay = {
        delay: this.$delay,
        disableOnInteraction: true,
        waitForTransition: true
      }
    }

    this.swiper = new Swiper(this.$slider, this.options);
    this.change();
  }


  change() {
    this.swiper.on('slideChange', () => {
      const videoActive = document.querySelector("[data-module-video].-active").querySelector("video");
      this.call('muted', { target: videoActive }, 'Video');
      this.call('unactive', null, 'SoundButton');

    });
  }

  destroy() {
    super.destroy();
    this.swiper.destroy();
  }
} 